import { ButtonHTMLAttributes, FC, memo } from "react";
import Spinner from "../assets/icons/spinner.svg?react";

import "../styles/components/customButton.css";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: "primary" | "secondary" | "danger";
  isLoading?: boolean;
  leftIcon?: FC<{ className?: string }>;
  buttonView?: "fill" | "outline";
}

export const CustomButton = memo(
  ({
    children,
    className,
    leftIcon: LeftIcon,
    title,
    onClick,
    type,
    isLoading,
    buttonView = "fill",
    color = "primary",
    ...rest
  }: ButtonProps) => {
    return (
      <button
        {...rest}
        className={`custom-button custom-button-view__${buttonView} custom-button-type__${color} ${className ? ` ${className}` : ""} custom-button-loading__${isLoading}`}
        title={title}
        onClick={onClick}
        type={type}
      >
        {LeftIcon && <LeftIcon className="custom-button-icon" />}
        {isLoading && (
          <div
            className={`custom-button-loading custom-button-loading-type__${color} `}
          >
            <Spinner />
          </div>
        )}
        {children}
      </button>
    );
  },
);

CustomButton.displayName = "CustomButton";
